<template>
  <div class="world calculator-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Terug naar offerte') }}</button>
        <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}: {{ $t('Configuratie') }} {{ selectedOptions.name === '' || !selectedOptions.name ? $t('rolluik') : selectedOptions.name }}</h1>
        <div class="flex"></div>
      </div>
    </div>

    <div class="calculator-wrapper">
      <div class="container">
        <div class="steps-wrapper">
          <div class="step-buttons">
            <h3>{{ $t('Configuratie') }}</h3>
            <CalculatorButton :activeStep="activeStep" :label=" $t('Naam') " :type="'name'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateName())" :label=" $t('Type') " :type="'type'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateType())" :label=" $t('Motorisatie') " :type="'motor'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateMotor())" :label=" $t('Breedte en hoogte') " :type="'size'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateWidth())" :label=" $t('Kleur & kastvorm') " :type="'cabinet_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Kleur lamellen') " :type="'slat_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateSlatColors())" :label=" $t('Opties') " :type="'options'" @setStep="setStep"/>
          </div>

          <div class="step-contents">
            <div v-if="activeStep === 'name'" :class="activeStep">
              <h2>{{ $t('Geef een naam aan je rolluik') }}</h2>

              <formulate-form>
                <formulate-input ref="name" v-model="selectedOptions.name" :label="$t('Naam')"></formulate-input>

                <div class="buttons">
                  <button :disabled="selectedOptions.name === '' || !selectedOptions.name" class="btn" @click="setStep('type')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'type'">
              <h2>{{ $t('Naar welk type rolluik ben je op zoek?') }}</h2>

              <div class="types-wrapper">
                <h3>{{ $t('PVC LAMELLEN') }}</h3>
                <div class="types types-shutter">
                  <button v-for="type in wpTypes" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                  </button>
                </div>

                <h3>{{ $t('ALUMINIUM LAMELLEN') }}</h3>
                <div class="types types-shutter">
                  <button v-for="type in waTypes" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                    <div class="description">{{ type.description }}</div>
                  </button>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!selectedOptions.type" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'controls'">
              <h2>{{ $t('Welk type bedieningssysteem wenst u?') }}</h2>
              <formulate-form>
                <formulate-input v-model="selectedOptions.connection" :disabled="selectedOptions.type === 'wa39'" :label="$t('Bediening')" :options="connections" type="select"></formulate-input>

                <div class="buttons">
                  <button :disabled="!(validateControls())" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'motor'">
              <h2>{{ $t('Selecteer het type motor') }}</h2>
              <formulate-input v-model="selectedOptions.engineConnection" :label="$t('Motor')" :options="enginesConnections" :placeholder="$t('Selecteer type motor')" type="select" @change="setEngine"></formulate-input>
              <div class="buttons">
                <button :disabled="!(validateMotor())" class="btn" @click="setStep('size')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'size'">
              <h2>{{ $t('Selecteer jouw breedte en hoogte') }}</h2>
              <formulate-form ref="widthForm" name="widthForm">

                <div v-if="selectedOptions.width && !maxHeight" class="error-message">
                  <span v-if="selectedOptions.measurement_style === 'outside'">
                    {{ $t('Let op: opgegeven breedte is te breed. Hou rekening met de geleiders (+ 110mm).') }}
                  </span> <span v-else>
                    {{ $t('Let op: opgegeven breedte is te breed.') }}
                  </span>
                </div>

                <div v-if="selectedOptions.height && !price" class="error-message">
                  {{ $t('Er kon geen prijs gevonden worden voor de opgegeven breedte en hoogte.') }}
                </div>

                <formulate-input @input="setWidth" type="radio" :options="{'default': $t('Standaard'), 'cabinet': $t('Kast'), 'cabinet_rails': $t('Kast + geleiders')}" v-model="selectedOptions.measurement_style"></formulate-input>

                <formulate-input
                  v-model="selectedOptions.width"
                  :help="$t('De minimale breedte is ') + minWidth + 'mm. ' + $t('De maximale breedte is ') + maxWidth + 'mm.'"
                  :label="$t('Breedte (mm)')"
                  :max="maxWidth"
                  :min="minWidth"
                  :placeholder="$t('Vul een breedte in')"
                  :validation="'required|number|between:' + (minWidth - 1) + ',' + (maxWidth + 1) + ',value'"
                  :validationMessages="{ between: $t('Breedte moet tussen ' + minWidth + ' en ' + maxWidth) }"
                  type="number"
                  @input="setWidth"
                ></formulate-input>

                <formulate-input
                  v-model="selectedOptions.height"
                  :help="maxHeight > 0 ? $t('De maximale hoogte is ') + maxHeight + 'mm.' : ''"
                  :label="$t('Hoogte (mm)')"
                  :max="maxHeight"
                  :placeholder="$t('Vul een hoogte in')"
                  :validation="'required|number|max:' + (maxHeight)"
                  :validationMessages="{
                    max: maxHeight > 0 ? $t('Hoogte (mm) moet kleiner of gelijk zijn aan ' + maxHeight) : '',
               }"
                  type="number"
                  @input="fetchPrice"
                ></formulate-input>

                <div class="buttons">
                  <button :disabled="!(validateWidth())" class="btn" @click="setStep('cabinet_colors')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'cabinet_colors'">
              <h2>{{ $t('Selecteer de kastkleur') }}</h2>

              <formulate-input v-if="selectedOptions.cabinet_type" v-model="selectedOptions.color_alternative" :label="$t('Alternatieve RAL-kleur')" type="checkbox" @change="setAlternativeColor"></formulate-input>

              <template v-if="selectedOptions.cabinet_type === 'cabinet_square'">
                <h3>{{ $t('Kast 45° - 9mm') }}</h3>
                <div class="colors">
                  <div v-for="color in cabinetSquareColors" :key="'cabinet-square-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square' }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('options')">
                    <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square'" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                    <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square' }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>
                    <span>{{ color.name }}</span>
                  </div>
                </div>
              </template>

              <template v-if="selectedOptions.cabinet_type === 'cabinet_round'">
                <h3>{{ $t('Kast Rond - 9mm') }}</h3>
                <div class="colors">
                  <div v-for="color in cabinetRoundColors" :key="'cabinet-round-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round' }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('options')">
                    <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round'" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                    <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round' }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>
                    <span>{{ color.name }}</span>
                  </div>
                </div>
              </template>

              <div class="cabinet-types">
                <button :class="{ 'active': selectedOptions.cabinet_type === 'cabinet_square' }" class="cabinet-type" @click="setCabinetType('cabinet_square')">
                  <img v-if="selectedOptions.cabinet_type === 'cabinet_square'" class="icon-check" alt="" src="@/assets/img/check_solid.svg" width="23">
                  <span class="image">
                    <img src="@/assets/img/calculator/cabinets/square.svg" alt="">
                  </span> {{ $t('Kast 45°') }}
                </button>

                <button :class="{ 'active': selectedOptions.cabinet_type === 'cabinet_round' }" class="cabinet-type" @click="setCabinetType('cabinet_round')">
                  <img v-if="selectedOptions.cabinet_type === 'cabinet_round'" class="icon-check" alt="" src="@/assets/img/check_solid.svg" width="23">
                  <span class="image">
                    <img src="@/assets/img/calculator/cabinets/round.svg" alt="">
                  </span> {{ $t('Kast rond') }}
                </button>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('slat_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'slat_colors'">
              <h2>{{ $t('Selecteer de kleur van de lamellen') }}</h2>

              <div class="colors">
                <div v-for="color in slatColors" :key="'slat-' + color.id" :class="{ 'active' : selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral }" class="color-wrapper" @click="selectSlatColor(color)" @dblclick="setStep('options')">
                  <Icon v-if="selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral }" :style="{ 'background-color': ralColors[color.ral] && ralColors[color.ral].color.hex }" class="color"></div>
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateSlatColors())" class="btn" @click="setStep('options')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'options'">
              <h2>{{ $t('Opties') }}</h2>
              <div class="help">{{ $t('Selecteer de opties die je wilt toevoegen') }}</div>
              <v-select ref="testeraa" v-model="selectedOptions.optionsSelected" :options="options" label="name" multiple @option:selecting="selectOption" @option:deselected="deselectOption"></v-select>

              <div class="option-inputs">
                <div v-for="option in selectedOptions.options" :key="option.id">
                  <div class="icon" @click="deselectOption(option)">
                    <Icon color="#cf5353" icon="clarity:remove-solid"/>
                  </div>
                  <formulate-input v-model="option.amount" :label="$t(option.name)" class="with-suffix" type="number">
                    <template #suffix>
                      <span class="suffix">{{ option.unit_type === 'piece' ? $t('aantal stuks') : $t('aantal lopende meter') }}</span>
                    </template>
                  </formulate-input>
                </div>
              </div>

              <div class="buttons">
                <button class="btn" @click="backToOverview">{{ $t('Configuratie opslaan en terugkeren naar offerte') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { Icon } from '@iconify/vue2'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import calculators from '@/calculators'

export default {
  name: 'Calculator',
  mixins: [
    calculators
  ],
  components: {
    Icon,
    CalculatorButton,
    vSelect
  },
  data () {
    return {
      price: null,
      quoteNumber: null,
      calculatorData: null,
      activeStep: 'name',
      cabinetRoundColors: [],
      cabinetSquareColors: [],
      slatColors: [],
      minWidth: null,
      maxWidth: null,
      maxHeight: null,
      wpTypes: [
        {
          key: 'wp37',
          label: 'WP37',
          description: '8,7mm x 37mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        }
      ],
      waTypes: [
        {
          key: 'wa39',
          label: 'WA39',
          description: '8,5mm x 39mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa40',
          label: 'WA40',
          description: '9mm x 40mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa40h',
          label: 'WA40H',
          description: '9mm x 40mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa55',
          label: 'WA55',
          description: '14mm x 55mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa55h',
          label: 'WA55H',
          description: '14mm x 55mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        }
      ],
      engineOptions: [],
      options: [],
      connections: {},
      enginesConnections: {},
      selectedOptions: {
        name: '',
        measurement_style: '',
        engineConnection: null,
        type: 'wa39',
        engine: null,
        cabinet_type: '',
        color: null,
        slat_color: null,
        color_alternative: false,
        options: [],
        optionsData: [],
        optionsSelected: []
      },
      datetime: DateTime
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
    await this.fetchShutter()

    if (this.selectedOptions.type) {
      await this.selectType(this.selectedOptions.type, false)
    }

    if (this.selectedOptions.engine) {
      await this.setEngine()
    }

    if (this.selectedOptions.width && this.selectedOptions.height) {
      await this.fetchPrice()
    }

    if (this.activeStep === 'name') {
      this.$refs.name.$el.querySelector('input').focus()
    }
  },
  methods: {
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    async fetchShutter () {
      if (this.$route.params.quoteId && this.$route.params.id) {
        await ApiService.fetchShutter(this.$route.params.id).then(async res => {
          this.calculatorData = res.data

          if (res.status === 200) {
            await this.normalizeCalculatorData(this.calculatorData)
          }
        })
      }
    },
    async fetchPrice () {
      await ApiService.fetchShutterPrice(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width, this.selectedOptions.height, this.selectedOptions.measurement_style).then(res => {
        this.price = res.data
      })
    },
    backToOverview () {
      this.setStep(this.activeStep)
      this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async normalizeCalculatorData (data) {
      this.selectedOptions = { ...this.selectedOptions, ...data }
      // this.selectedOptions.ral = parseInt(this.selectedOptions.ral)
      // this.selectedOptions.options = data.options

      this.selectedOptions.optionsSelected = []

      for (const option of this.selectedOptions.options) {
        const foundOption = this.options.find(optionObj => {
          if (optionObj.internal_name === option.name) {
            return optionObj
          }
        })

        if (foundOption) {
          this.selectedOptions.optionsSelected.push(foundOption)
        }
      }
      // this.selectedOptions.optionsSelected = data.options.map(option => {
      //   option.internal_name = option.name
      //   option.name = this.$t(option.name)
      //   return option
      // })

      if (this.selectedOptions.engine && this.selectedOptions.connection) {
        this.selectedOptions.engineConnection = this.selectedOptions.engine + '_' + this.selectedOptions.connection
      }

      this.selectedOptions.color = data.colors.find(color => color.component === 'cabinet_square' || color.component === 'cabinet_round')
      if (!this.selectedOptions.cabinet_type) {
        this.selectedOptions.cabinet_type = this.selectedOptions?.color?.component
      }
      if (this.selectedOptions.color && !this.selectedOptions.color.ral) {
        this.selectedOptions.color_alternative = true
      }
      this.selectedOptions.slat_color = data.colors.find(color => color.component === 'slats')
    },
    /** validatons methods **/
    validateName () {
      if (this.selectedOptions.name && this.selectedOptions.name !== '') {
        return true
      }

      return false
    },
    validateType () {
      if (this.validateName() && this.selectedOptions.type) {
        return true
      }

      return false
    },
    validateControls () {
      if (this.validateType() && this.selectedOptions.connection) {
        return true
      }

      return false
    },
    validateMotor () {
      if (this.validateType() && this.selectedOptions.engine) {
        return true
      }

      return false
    },
    validateWidth () {
      if (this.validateMotor() && this.selectedOptions.width >= this.minWidth && this.selectedOptions.width <= this.maxWidth && this.selectedOptions.height && this.selectedOptions.height <= this.maxHeight && this.price) {
        return true
      }

      return false
    },
    validateColors () {
      if (this.validateWidth() && this.selectedOptions.color) {
        return true
      }

      return false
    },
    validateSlatColors () {
      if (this.validateColors() && this.selectedOptions.slat_color) {
        return true
      }

      return false
    },
    async setStep (step) {
      this.calculatorData = { ...this.calculatorData, ...this.selectedOptions }
      this.calculatorData.width = parseInt(this.calculatorData.width)

      if (this.activeStep === 'options' && this.calculatorData.options.length > 0) {
        for (const option of this.calculatorData.options) {
          await ApiService.patchShutterOption(this.calculatorData.id, option.id, option)
        }
      }

      if (this.activeStep === 'motor' && this.validateMotor()) {
        await this.setEngine()
      }

      delete this.calculatorData.added_height
      delete this.calculatorData.added_width
      delete this.calculatorData.shortened_engine

      await ApiService.patchShutter(this.calculatorData.id, this.calculatorData).then(async res => {
        await this.normalizeCalculatorData(res.data)
      })

      this.activeStep = step
    },
    async selectType (type, resetEngineConnection = true) {
      this.selectedOptions.type = type

      if (resetEngineConnection) {
        this.selectedOptions.engine = null
        this.selectedOptions.connection = null

        await this.deleteSelectedColor()
        await this.deleteSelectedSlatColor()
      }

      await this.fetchCalculatorEnginesConnections()
      await this.setWidth()
    },
    async fetchCalculatorEnginesConnections () {
      await ApiService.fetchShutterEnginesConnections(this.selectedOptions.type).then(async res => {
        this.enginesConnections = {}

        res.data.forEach(engineConnection => {
          this.enginesConnections[engineConnection.engine + '_' + engineConnection.connection] = this.$t(engineConnection.engine) + ' - ' + this.$t(engineConnection.connection)
        })

        if (Object.keys(this.enginesConnections).length === 1) {
          this.selectedOptions.engineConnection = Object.keys(this.enginesConnections)[0]
          await this.setEngine()
        }
      })
    },
    async setEngine () {
      if (this.selectedOptions.engineConnection) {
        const engineConnection = this.selectedOptions.engineConnection.split('_')
        this.selectedOptions.engine = engineConnection[0]
        this.selectedOptions.connection = engineConnection[1]
      }

      await this.fetchCalculatorInfo()
      await ApiService.fetchShutterOptions(this.selectedOptions.type).then(res => {
        this.options = res.data.map(option => {
          option.internal_name = option.name
          option.name = this.$t(option.name)
          return option
        }).sort((a, b) => a.name > b.name ? 1 : -1)
      })
    },
    async setCabinetType (type) {
      this.selectedOptions.cabinet_type = type

      await this.deleteSelectedColor()
      await this.deleteSelectedSlatColor()
      this.selectedOptions.color_alternative = false
    },
    async deleteSelectedColor () {
      if (this.selectedOptions.color) {
        await ApiService.deleteShutterColor(this.$route.params.id, this.selectedOptions.color.id)
        this.selectedOptions.color = null
      }
    },
    async deleteSelectedSlatColor () {
      if (this.selectedOptions.slat_color) {
        await ApiService.deleteShutterColor(this.$route.params.id, this.selectedOptions.slat_color.id)
        this.selectedOptions.slat_color = null
      }
    },
    async setAlternativeColor () {
      await this.deleteSelectedColor()

      await ApiService.createShutterColor(this.$route.params.id, { component: this.selectedOptions.cabinet_type }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.color = res.data
        }
      })
    },
    async selectColor (color) {
      await this.deleteSelectedColor()
      this.selectedOptions.color_alternative = false

      await ApiService.createShutterColor(this.$route.params.id, {
        color_id: color.id,
        component: color.component
      }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.color = res.data
        }
      })
    },
    async selectSlatColor (color) {
      await this.deleteSelectedSlatColor()

      await ApiService.createShutterColor(this.$route.params.id, {
        color_id: color.id,
        component: color.component
      }).then(res => {
        if (res.status === 200) {
          this.selectedOptions.slat_color = res.data
        }
      })
    },
    async fetchCalculatorInfo () {
      await ApiService.fetchShutterInfo(this.selectedOptions.type, this.selectedOptions.engine).then((res) => {
        if (res.data) {
          this.minWidth = res.data.width[0]
          this.maxWidth = res.data.width[1]
          this.cabinetSquareColors = res.data.colors.cabinet_square
          this.cabinetRoundColors = res.data.colors.cabinet_round
          this.slatColors = res.data.colors.slats
        }
      })
    },
    async setWidth () {
      if (this.validateMotor() && this.selectedOptions.width) {
        await ApiService.fetchShutterHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width, this.selectedOptions.measurement_style).then(res => {
          this.maxHeight = res.data || 0
        })
      }
    },
    /** options methods **/
    async selectOption (option) {
      // await this.setStep(this.activeStep)

      const foundOption = this.selectedOptions.options.find(optionObj => optionObj.name === option.internal_name)

      if (!foundOption) {
        const obj = {
          option_id: option.id,
          amount: 1
        }

        await ApiService.createShutterOption(this.calculatorData.id, obj)

        await this.fetchShutter()
      }
    },
    async selectSubOption (internalName) {
      const option = this.findOptionByInternalName(internalName)
      await ApiService.createShutterOption(this.calculatorData.id, option)
      await this.fetchShutter()
      this.optionHomemotion = false
    },
    async deselectOption (option) {
      // delete option based on input options
      let quoteOption = option

      if (!Object.keys(option).includes('calculated_total')) {
        // delete option based on select data
        quoteOption = this.selectedOptions.options.find(opt => option.internal_name === opt.name)
      }

      // this.selectedOptions.optionsSelected
      if (quoteOption) {
        await ApiService.deleteShutterOption(this.calculatorData.id, quoteOption.id)
        await this.fetchShutter()
      }
    },
    findOptionByInternalName (internalName) {
      const foundOption = this.options.find(optionObj => optionObj.internal_name === internalName)

      if (foundOption) {
        const existingOption = this.selectedOptions.options.find(optionObj => optionObj.option_id === foundOption.id)

        if (!existingOption) {
          return {
            option_id: foundOption.id,
            amount: 0
          }
        }
      }

      return null
    }
  },
  computed: {
    ralColorsSorted () {
      if (this.ralSearch === '') {
        return []
      }

      const filteredRals = {}
      Object.keys(this.ralColors).forEach(ral => {
        if (ral.includes(this.ralSearch) || ('RAL' + ral).includes(this.ralSearch)) {
          filteredRals[ral] = this.ralColors[ral]
        }
      })
      return filteredRals
    }
  }
}
</script>
